.fadeIn {
  animation: fadeIn 250ms ease-in;
  -webkit-animation: fadeIn 250ms ease-in;
  -moz-animation: fadeIn 250ms ease-in;
  -o-animation: fadeIn 250ms ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grayscale {
  0%,
  100% {
    filter: grayscale(0);
  }
  50% {
    filter: grayscale(1);
  }
}

@-moz-keyframes grayscale {
  0%,
  100% {
    filter: grayscale(0);
  }
  50% {
    filter: grayscale(1);
  }
}

@-webkit-keyframes grayscale {
  0%,
  100% {
    filter: grayscale(0);
  }
  50% {
    filter: grayscale(1);
  }
}

@-o-keyframes grayscale {
  0%,
  100% {
    filter: grayscale(0);
  }
  50% {
    filter: grayscale(1);
  }
}
