@import './colors';

.rdg {
  background: $grid-container;
  border-radius: 0.5rem;
  border: 0.0625rem solid $system-default-colors-primary-colors-light-purple;
  color: $text-color;
  font-family: 'Inter';
  font-weight: 400;
  max-height: 50rem;
  height: auto;

  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.5rem;
    background-color: $grid-cell-border;
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $system-default-colors-primary-colors-dark-grey;
    outline: none;
    border-radius: 0.625rem;
  }

  &-row {
    width: 100%;

    &:nth-of-type(odd) {
      .rdg-cell {
        background: $grid-row-even;
      }
    }

    &:hover {
      cursor: pointer;
      background: inherit;
    }
  }

  &-header {
    background: $grid-header-color;

    &-sort-name {
      padding-right: 1rem;

      & path {
        stroke: $secondary-main;
      }
    }

    &-row {
      width: 100%;

      .rdg-cell {
        color: $text-color;
        background: $grid-header-color;
        position: sticky;
        min-width: 10rem;

        &:last-of-type {
          justify-content: center;
        }
      }
    }
  }

  &-sort-icon {
    path {
      stroke: $secondary-main;
    }
  }

  &-cell {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    position: relative;
    padding: 0 1rem;
    border: none;
    color: $text-color;
    display: flex;
    align-items: center;
    outline: none;
    background: $grid-container;

    &-inner {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-frozen {
      position: sticky;
    }

    &:before {
      content: '';
      position: absolute;
      height: 0.875rem;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border-right: 0.0625rem solid $grid-cell-border;
    }

    &:last-of-type {
      justify-content: center;
      &:before {
        content: none;
      }
    }
  }
}

.print-table {
  border-collapse: collapse;
  font-family: 'Inter';
  background: $grid-container;

  &-td {
    padding: 0.5rem;
    color: $text-color;
    border: 1px solid #54585d;
  }

  &-header {
    &-td {
      font-weight: bold;
      font-size: 0.875rem;
      border: 1px solid #54585d;
      padding: 0.5rem;
      background: $grid-header-color;
    }
  }

  &-row {
    &:nth-of-type(odd) {
      .print-table-td {
        background: $grid-row-even;
      }
    }
  }
}
